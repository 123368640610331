@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-gray-900 bg-white;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, sans-serif;
  }
}

@layer components {
  .gradient-text {
    @apply bg-gradient-to-r from-primary to-primary-dark bg-clip-text text-transparent;
  }
}
